
import { defineComponent, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import useNavigationComplete from '@/composables/useNavigationComplete'
import FormName from '@/components/form/FormName.vue'
import FormEmail from '@/components/form/FormEmail.vue'
import FormTel from '@/components/form/FormTel.vue'
import FormDni from '@/components/form/FormDni.vue'
import FormAddress from '@/components/form/FormAddress.vue'
import FormLocationText from '@/components/form/FormLocationText.vue'
import FormCheck from '@/components/form/FormCheck.vue'
import { useI18n } from 'vue-i18n'
import FormProvinceText from '@/components/form/FormProvinceText.vue'

export interface PersonalDateData {
  nombreApellido: string
  email: string
  telefono: number
  documento: number
  direccion: string
  provincia: string
  localidad: string
}

export default defineComponent({
  components: {
    FormName,
    FormEmail,
    FormTel,
    FormDni,
    FormAddress,
    FormLocationText,
    FormCheck,
    FormProvinceText
},
  props: {
    modelValue: {
      type: Object as () => PersonalDateData
    },
    simplificado: {
      type: Boolean,
      default: false
    },
    indispensables: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { state, getters } = useStore<VuexStore>(key)
    const updatePersonalData = ref<boolean>(false)
    const { navigation } = useNavigationComplete()
    const disabledInput = (inputValue: number | string | boolean): Boolean => {
      return Boolean(getters.isLogin && inputValue && !updatePersonalData.value)
    }

    const disablePreLoading = reactive({
      nombreApellido:false,
      documento:false,
      email:false
    })

    if(props.modelValue?.documento){
      disablePreLoading.documento = true
    }
    if(props.modelValue?.nombreApellido){
      disablePreLoading.nombreApellido = true
    }
    if(props.modelValue?.email || navigation.preDatos?.email){
      disablePreLoading.email = true
    }

    const formulario = reactive({
      nombreApellido: state.authUser
        ? props.modelValue?.nombreApellido
        : '',
      email: props.modelValue?.email || navigation.preDatos?.email || '',
      telefono: props.simplificado? '--' : '',
      documento: props.simplificado? '--' : (props.modelValue?.documento) ? props.modelValue?.documento :null,
      direccion: props.simplificado? '--' : '' ,
      provincia: props.simplificado? '--' : '',
      localidad: props.simplificado? '--' : ''
    })

    emit('update:modelValue', formulario)

    watch(formulario, (value) => {

      if(props.indispensables){
        if(!value.telefono) value.telefono = '--'
        if(!value.direccion) value.direccion = '--'
        if(!value.provincia) value.provincia = '--'
        if(!value.localidad) value.localidad = '--'
      }

      emit('update:modelValue', value)
    })

    return {
      t,
      updatePersonalData,
      disabledInput,
      getters,
      formulario,
      disablePreLoading
    }
  }
})
